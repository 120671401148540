import React, { useCallback, useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import VoiceRecorder from "./VoiceRecorder";


const UnityWebViewer = ({ experienceId }) => {

  const buildPrefix = "/webplayer/1.1.18_web";
  const voiceRecorderRef = useRef(null);

  const { unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded } = useUnityContext({
    loaderUrl: `${buildPrefix}.loader.js`,
    dataUrl: `${buildPrefix}.data`,
    frameworkUrl:`${buildPrefix}.framework.js`,
    codeUrl: `${buildPrefix}.wasm`,
  });

  

  const sendMessageToUnity = (gameObject, methodName, value) => {
    // Send a message to a GameObject's method with a value
    console.log(unityProvider)
    sendMessage(gameObject, methodName, value);
  };

  const handleElementSelected = useCallback((elementData, selectionPoint) => {
    console.log("ELEMENT SELECTED", elementData);
  }, []);

  const handleEnvironmentChanged = useCallback((environmentData) => {
    console.log("ENVIRONMENT CHANGED", environmentData);
  }, []);

   const handleRecordingStarted = useCallback((instanceId) => {
    console.log("RECORDING STARTED", instanceId);
     if (voiceRecorderRef.current){
       voiceRecorderRef.current.startRecording(instanceId);
     }
   }, []);

   const handleUploadAudio = useCallback((presignedUrl) => {
    console.log("UPLOADING VIA PRESIGNED URL", presignedUrl);
     if (voiceRecorderRef.current){
       voiceRecorderRef.current.uploadFileToS3(presignedUrl);
     }
   }, []);

     const handleReadyToLoadExperience = useCallback((message) => {
       console.log("READY TO LOAD EXPERIENCE", message);
       console.log(experienceId)
       // Add a 1-second delay before sending the message
      //  setTimeout(() => {
         sendMessageToUnity("EventDispatcher", "LoadExperience", experienceId);
      //  }, 1000);
     }, [isLoaded]);



  useEffect(() => {
 


    console.log("INITIALIZING")
    addEventListener("elementSelected", handleElementSelected);
    addEventListener("environmentChanged", handleEnvironmentChanged);
    addEventListener("recordingStarted", handleRecordingStarted);
    addEventListener("uploadAudioFile", handleUploadAudio);
    addEventListener("readyToLoadExperience", handleReadyToLoadExperience);
    return () => {
      removeEventListener("elementSelected", handleElementSelected);
      removeEventListener("environmentChanged", handleEnvironmentChanged);
      removeEventListener("recordingStarted", handleRecordingStarted);
      removeEventListener("uploadAudioFile", handleUploadAudio);
      removeEventListener("readyToLoadExperience", handleReadyToLoadExperience);

    };
  }, [addEventListener, removeEventListener, handleElementSelected, handleEnvironmentChanged, handleRecordingStarted, handleUploadAudio, handleReadyToLoadExperience, isLoaded]);




  const doneRecording = () =>
  {
    console.log("stopping recording")
    sendMessageToUnity("EventDispatcher", "RecordingStopped");
  }

  const assessmentComplete = () => {
    sendMessageToUnity("EventDispatcher", "AssessmentComplete");
  }

  

  return (
    <div className="content">
      <Unity
        unityProvider={unityProvider}
        style={{ width: "100%", height: "100%" }}
        devicePixelRatio={window.devicePixelRatio}
        width="100%"
        height="100%"
      />
      {/* <button onClick={() => sendMessageToUnity("EventDispatcher", "RecordingStopped", "Hello, Unity")}>Send message to Unity</button> */}
      <VoiceRecorder
        doneRecording={doneRecording}
        assessmentComplete={assessmentComplete}
        ref={voiceRecorderRef}
      />
    </div>
  );


};

export default UnityWebViewer;
